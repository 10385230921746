<template>
  <div id="cover">
    <div class="loader smooth" :style="{ opacity: loading ? 1 : 0 }">
      <img src="//app.verified.eu/lib/iframes/common/assets/loader64.gif" />
    </div>
    <div class="container main" v-if="!loading">
      <ve-row>
        <ve-col>
          <!-- #region form1 -->
          <div class="card border-0 shadow-sm">
            <div class="card-body">
                  <ve-row>
                      <ve-col>
                        <img width="200px" class="logo" :src="'./logo.png'" />
                      </ve-col>
                      <ve-col>
                        <h2>{{__('title')}}</h2>
                      </ve-col>
                  </ve-row>
               
              <br>
              <ve-form ref="form1" class="ml-3" v-if="!loading && !submitted">
                 <ve-row>
                  <ve-col>
                    <ve-headline :headline="__('info2')" size="1em"></ve-headline>
                  </ve-col>
                 </ve-row>
                 <br><br>
                <ve-row>
                     <ve-input-field
                          md6 xs12
                          :label="__('circuitName')"
                          required
                          type="text"
                          rules="required"
                          v-model="ud.circuitName"
                          :readonly="role=='redcross-editor'"
                        />
                        <ve-input-field
                          md6 xs12
                          :label="__('muncipality')"
                          required
                          type="text"
                          rules="required"
                          v-model="ud.muncipality"
                          :readonly="role=='redcross-editor'"
                        />
                </ve-row>
                <ve-row>
                        <ve-input-field
                          md6 xs12
                          :label="__('idNumber')"
                          required
                          type="text"
                          rules="required"
                          v-model="ud.idNumber"
                          :readonly="role=='redcross-editor'"
                        />
                </ve-row>
                <ve-row>
                        <ve-input-field
                          md12 xs12
                          :label="__('capital31')"
                          required
                          type="text"
                          rules="required"
                          v-model="ud.capital31"
                          :readonly="role=='redcross-editor'"
                        />
                </ve-row>
                <ve-row style="margin-bottom:-20px;">
                  <ve-col>
                    <ve-label
                      :label="__('capitalApplication')"
                      required
                    />
                  </ve-col>
                </ve-row>
                <ve-row>
                        <ve-input-field
                          md6 xs12
                          type="text"
                          rules="required"
                          v-model="ud.capitalApplication"
                          :readonly="role=='redcross-editor'"
                        />
                        <ve-col md6 xs12>
                        <ve-form-group>
                          <ve-label
                          />
                          <datepicker v-model="ud.capitalApplicationDate"
                            name="capitalApplicationDate"
                            id="capitalApplicationDate"
                            :language="lang==='sv_SE' ? sv : en"
                            format="dd.MM.yyyy"
                            :monday-first="true"
                            :input-class="'calendar-icon form-control' + (ud.capitalApplicationDate ? ' is-valid' : ' is-invalid')"
                            :disabled="role==='redcross-editor'"
                          ></datepicker>
                          <div class="invalid-feedback" v-if="showInvalidFeedback['capitalApplicationDate']"
                              :style="{ display: ud.capitalApplicationDate ? 'none' : 'block' }">
                            {{ __('required') }}
                          </div>
                        </ve-form-group>
                      </ve-col>
                </ve-row>
                  <ve-row>
                       <ve-col>
                        <ve-headline :headline="__('info3')" size="1em"></ve-headline>
                      </ve-col>
                </ve-row>
                <br>
                <ve-row>
                  <ve-col>
                  <ve-label
                    :label="__('info3sub')"
                  />
                  </ve-col>
                </ve-row>
                <br>
                <ve-row>
                     <ve-input-field
                          md6 xs12
                          :label="__('personalCost')"
                          required
                          type="text"
                          rules="required"
                          v-model="ud.personalCost"
                          :readonly="role=='redcross-editor'"
                        />
                        <ve-input-field
                          md6 xs12
                          :label="__('rentalCost')"
                          required
                          type="text"
                          rules="required"
                          v-model="ud.rentalCost"
                          :readonly="role=='redcross-editor'"
                        />
                     <ve-input-field
                          md6 xs12
                          :label="__('otherExpense')"
                          required
                          type="text"
                          rules="required"
                          v-model="ud.otherExpense"
                          :readonly="role=='redcross-editor'"
                        />
                        
                     <ve-input-field
                          md6 xs12
                          :label="__('total')"
                          type="text"
                          v-model="ud.totalExpense"
                          :readonly="true"
                        />
                </ve-row>
                <hr>
                <ve-row>
                  <ve-input-field
                          md6 xs12
                          :label="__('estimatedRevenue')"
                          required
                          type="text"
                          rules="required"
                          v-model="ud.estimatedRevenue"
                          :readonly="role=='redcross-editor'"
                        />
                        <ve-input-field
                          md6 xs12
                          :label="__('secondhand')"
                          required
                          type="text"
                          rules="required"
                          v-model="ud.secondhand"
                          :readonly="role=='redcross-editor'"
                        />
                        <ve-input-field
                          md6 xs12
                          :label="__('contribution')"
                          required
                          type="text"
                          rules="required"
                          v-model="ud.contribution"
                          :readonly="role=='redcross-editor'"
                        />
                      <ve-input-field
                          md6 xs12
                          :label="__('total')"
                          type="text"
                          rules="required"
                          v-model="ud.totalIncome"
                          :readonly="true"
                        />
                </ve-row>
                <ve-row>
                      <ve-col md12 xs12 >
                        <ve-form-group>
                          <ve-label
                            class="required"
                            :label="__('situation')"
                            />
                          <ve-custom-textarea
                            rules="required"
                            v-model="ud.situation"
                            :readonly="role==='redcross-editor'"
                          />
                        </ve-form-group>
                      </ve-col>
                    </ve-row>
                    
                    <ve-row>
                      <ve-col md12 xs12 >
                        <ve-form-group>
                          <ve-label
                            class="required"
                            :label="__('planMeasure')"
                            />
                          <ve-custom-textarea
                            rules="required"
                            v-model="ud.planMeasure"
                            :readonly="role==='redcross-editor'"
                          />
                        </ve-form-group>
                      </ve-col>
                    </ve-row>
                    
                    <ve-row>
                      <ve-col md12 xs12 >
                        <ve-form-group>
                          <ve-label
                            class="required"
                            :label="__('describe')"
                            />
                          <ve-custom-textarea
                            rules="required"
                            v-model="ud.describe"
                            :readonly="role==='redcross-editor'"
                          />
                        </ve-form-group>
                      </ve-col>
                    </ve-row>
                    <ve-row>
                  <ve-col>
                  <ve-label
                    :label="__('info4')"
                  />
                  </ve-col>
                </ve-row>
                <br>
                 <ve-row>
                  <ve-col>
                  <ve-label
                    :label="__('weWish')"
                  />
                  </ve-col>
                </ve-row>
                <ve-row>
                  <ve-col md6 xs12>

                        <ve-form-group>
                          <ve-custom-radio
                          :options="{
                            shortTerm: { label: __('shortTerm'), tip:{text:'info-tooltip1',html:true} },
                            contribution: { label: __('contrib'), tip:{text:'info-tooltip2',html:true} }
                          }"

                          v-model="ud.loanType"
                          rules="required"
                          :disabled="role==='redcross-editor'"
                          />
                          
                        </ve-form-group>
                        
                      </ve-col>
                </ve-row>
                <ve-row  v-if="ud.loanType.shortTerm">
                      <ve-col md6 xs12>
                        <ve-form-group>
                          <ve-label
                          :label="__('expectedDate')"
                          required
                          />
                          <datepicker v-model="ud.expectedDate"
                            name="datepicker_expected_date"
                            id="datepicker-expected-date"
                            :language="lang==='sv_SE' ? sv : en"
                            format="dd.MM.yyyy"
                            :monday-first="true"
                            :input-class="'calendar-icon form-control' + (ud.expectedDate ? ' is-valid' : ' is-invalid')"
                            :disabled="role==='redcross-editor'"
                          ></datepicker>
                          <div class="invalid-feedback" v-if="showInvalidFeedback['datepicker-expected-date']"
                              :style="{ display: ud.expectedDate ? 'none' : 'block' }">
                            {{ __('required') }}
                          </div>
                        </ve-form-group>
                      </ve-col>
                       <ve-input-field
                          md6 xs12
                          :label="__('enter1')"
                          required
                          type="text"
                          rules="required|nordic_numbermax150"
                          v-model="ud.enter1"
                          :readonly="role=='redcross-editor'"
                        />
                </ve-row>
                <ve-row v-if="ud.loanType.contribution">
                   <ve-input-field
                          md6 xs12
                          :label="__('enter2')"
                          required
                          type="text"
                          rules="required|nordic_numbermin500"
                          v-model="ud.enter2"
                          :readonly="role=='redcross-editor'"
                        />
                </ve-row>
               
                <br>
                 <ve-row>
                  <ve-col>
                    <ve-headline :headline="__('verification')" size="1em"></ve-headline>
                  </ve-col>
                 </ve-row>
                 <br>
                 <ve-row>
                   <ve-col md6 xs12>
                   <ve-form-group>
                          <ve-label
                            :label="__('boardDesition')"
                            required
                          />
                          <ve-custom-radio
                          v-model="ud.boardDesition"
                          :options="{
                            yes: { label: __('yes') },
                            no: { label: __('no') }
                          }"
                          rules="required"
                          :disabled="role==='redcross-editor'"
                          inline
                          />
                        </ve-form-group>
                       </ve-col>
                       <ve-col md6 xs12 v-if="ud.boardDesition.yes">
                        <ve-form-group>
                          <ve-label
                          :label="__('dateDesition')"
                          required
                          />
                          <datepicker v-model="ud.dateDesition"
                            name="datepicker_desition_date"
                            id="datepicker-desition-date"
                            :language="lang==='sv_SE' ? sv : en"
                            format="dd.MM.yyyy"
                            :monday-first="true"
                            :input-class="'calendar-icon form-control' + (ud.dateDesition ? ' is-valid' : ' is-invalid')"
                            :disabled="role==='redcross-editor'"
                          ></datepicker>
                          <div class="invalid-feedback" v-if="showInvalidFeedback['datepicker-desition-date']"
                              :style="{ display: ud.dateDesition ? 'none' : 'block' }">
                            {{ __('required') }}
                          </div>
                        </ve-form-group>
                      </ve-col>
                 </ve-row>

                               
                <br>
                 <ve-row>
                  <ve-col>
                    <ve-headline :headline="__('turnOverHead')" size="1em"></ve-headline>
                  </ve-col>
                 </ve-row>
                 <br>
                 <ve-row>
                   <ve-col md12 xs12>
                   <ve-form-group>
                          <ve-label
                            :label="__('turnOverQstn')"
                            required
                          />
                          <ve-custom-radio
                          v-model="ud.turnOverQstn"
                          :options="{
                            turnOverYes: { label: __('yes') },
                            turnOverNo: { label: __('no') }
                          }"
                          rules="required"
                          :disabled="role==='redcross-editor'"
                          inline
                          />
                        </ve-form-group>
                       </ve-col>
                       <ve-col md12 xs12 v-if="ud.turnOverQstn.turnOverYes">
                        <ve-form-group>
                          <ve-label
                            :label="__('turnOverQstn1')"
                            required
                          />
                          <ve-custom-radio
                          v-model="ud.turnOverQstn1"
                          :options="{
                            turnOverYes1: { label: __('yes') },
                            turnOverNo1: { label: __('no') }
                          }"
                          rules="required"
                          :disabled="role==='redcross-editor'"
                          inline
                          />
                        </ve-form-group>


                        <ve-col v-if="ud.turnOverQstn1.turnOverYes1">
                          <ve-form-group>
                            <ve-label
                              :label="__('turnOverQstn2')"
                              required
                            />
                            <ve-custom-textarea
                              rules="required"
                              v-model="ud.turnOverQstn2"
                              :readonly="role=='redcross-editor'"
                            />
                            
                          </ve-form-group>
                        </ve-col>


                        <ve-col v-if="ud.turnOverQstn1.turnOverNo1">
                          <ve-form-group>
                            <ve-label
                              :label="__('turnOverQstn3')"
                              required
                            />
                            <ve-custom-textarea
                              rules="required"
                              v-model="ud.turnOverQstn3"
                              :readonly="role=='redcross-editor'"
                            />
                            
                          </ve-form-group>
                        </ve-col>
                      </ve-col>
                 </ve-row>



                 <ve-row>
                  <ve-col>
                    <ve-headline :headline="__('payment')" size="1em"></ve-headline>
                    <ve-label
                            :label="__('paymentInfo')"
                          />
                  </ve-col>
                 </ve-row>
                 <br>
                  <ve-row>
                    <ve-col>
                      <ve-headline :headline="__('chairmanInfo')" size="1em"></ve-headline>
                    </ve-col>
                  </ve-row>
                  <br>
                    <ve-row>
                    <ve-input-field
                      md6 xs12
                      required
                      :label="__('firstName')"
                      type="text"
                      rules="required"
                      v-model="ud.chairman.firstName"
                      :readonly="role=='redcross-editor'"
                    />
                    <ve-input-field
                      md6 xs12
                      required
                      :label="__('lastName')"
                      type="text"
                      rules="required"
                      v-model="ud.chairman.lastName"
                      :readonly="role=='redcross-editor'"
                    />
                  </ve-row>
                  <ve-row>
                    <ve-input-field
                      md6 xs12
                      required
                      :label="__('phone')"
                      type="phone"
                      rules="required|number"
                      v-model="ud.chairman.phone"
                      :readonly="role=='redcross-editor'"
                    />
                    <ve-input-field
                      md6 xs12
                      required
                      :label="__('email')"
                      type="email"
                      rules="required|email"
                      v-model="ud.chairman.email"
                      :readonly="role=='redcross-editor'"
                    />
                  </ve-row>
                   <ve-row>
                    <ve-col>
                      <ve-headline :headline="__('treasurer')" size="1em"></ve-headline>
                    </ve-col>
                  </ve-row>
                  <br>
                    <ve-row>
                    <ve-input-field
                      md6 xs12
                      :label="__('firstName')"
                      type="text"
                      v-model="ud.treasurer.firstName"
                      :readonly="role=='redcross-editor'"
                    />
                    <ve-input-field
                      md6 xs12
                      :label="__('lastName')"
                      type="text"
                      v-model="ud.treasurer.lastName"
                      :readonly="role=='redcross-editor'"
                    />
                  </ve-row>
                  <ve-row>
                    <ve-input-field
                      md6 xs12
                      :label="__('phone')"
                      type="phone"
                      v-model="ud.treasurer.phone"
                      :readonly="role=='redcross-editor'"
                    />
                    <ve-input-field
                      md6 xs12
                      :label="__('email')"
                      type="email"
                      v-model="ud.treasurer.email"
                      :readonly="role=='redcross-editor'"
                    />
                  </ve-row>
                   <ve-row>
                    <ve-col md6>
                      <ve-headline :headline="__('signerInfo')" size="1em"></ve-headline>
                    </ve-col>
                    <ve-col md6>
                    <button v-if="role!=='redcross-editor'" class="btn-xs btn-primary" type="button" @click="copyData('treasurer')">{{__('treasurer_copy')}}</button>&nbsp;&nbsp;&nbsp;
                    <button v-if="role!=='redcross-editor'" class="btn-xs btn-primary" type="button" @click="copyData('chairman')">{{__('chairman_copy')}}</button>
                    </ve-col>
                  </ve-row>
                  <br>
                    <ve-row>
                    <ve-input-field
                      md6 xs12
                      required
                      :label="__('firstName')"
                      type="text"
                      rules="required"
                      v-model="ud.signerInfo.firstName"
                      :readonly="role=='redcross-editor'"
                    />
                    <ve-input-field
                      md6 xs12
                      required
                      :label="__('lastName')"
                      type="text"
                      rules="required"
                      v-model="ud.signerInfo.lastName"
                      :readonly="role=='redcross-editor'"
                    />
                  </ve-row>
                  <ve-row>
                    <ve-input-field
                      md6 xs12
                      required
                      :label="__('phone')"
                      type="phone"
                      rules="required|number"
                      v-model="ud.signerInfo.phone"
                      :readonly="role=='redcross-editor'"
                    />
                    <ve-input-field
                      md6 xs12
                      required
                      :label="__('email')"
                      type="email"
                      rules="required|email"
                      v-model="ud.signerInfo.email"
                      :readonly="role=='redcross-editor'"
                    />
                  </ve-row>
                  <ve-row v-if="role=='redcross-editor' && enableForwarding">
                    <ve-col md12 xs12>
                      <ve-form-group>
                        <ve-checkbox
                          v-model="ud.shouldForward"
                          :options="{yes:{label:__('shouldForward')}}"
                        />
                      </ve-form-group>
                    </ve-col>
                  </ve-row>
                  <ve-row v-if="ud.shouldForward.yes">
                    <ve-input-field
                      md6 xs12
                      required
                      :label="__('firstName')"
                      type="text"
                      rules="required"
                      v-model="ud.approverInfo.firstName"
                      :readonly="role!=='redcross-editor'"
                    />
                    <ve-input-field
                      md6 xs12
                      required
                      :label="__('lastName')"
                      type="text"
                      rules="required"
                      v-model="ud.approverInfo.lastName"
                      :readonly="role!=='redcross-editor'"
                    />
                  </ve-row>
                  <ve-row v-if="ud.shouldForward.yes">
                    <ve-input-field
                      md6 xs12
                      required
                      :label="__('phone')"
                      type="phone"
                      rules="required|number"
                      v-model="ud.approverInfo.phone"
                      :readonly="role!=='redcross-editor'"
                    />
                    <ve-input-field
                      md6 xs12
                      required
                      :label="__('email')"
                      type="email"
                      rules="required|email"
                      v-model="ud.approverInfo.email"
                      :readonly="role!=='redcross-editor'"
                    />
                  </ve-row>
                  <ve-row v-if="role=='redcross-editor'">
                      <ve-col md12 xs12 >
                        <ve-form-group>
                          <ve-label
                            :label="__('comment')"
                            />
                          <ve-custom-textarea
                            v-model="ud.comment"
                            :readonly="role!=='redcross-editor'"
                          />
                        </ve-form-group>
                      </ve-col>
                    </ve-row>
                    <ve-row v-if="role=='redcross-editor'">
                      <ve-col md12 xs12>
                        <ve-form-group>
                          <ve-label
                            :label="__('decision')"
                            required
                          />
                          <ve-custom-radio
                          v-model="ud.decision"
                          :options="{
                            admitted: { label: __('admitted') },
                            rejected: { label: __('rejected') },
                            refused: { label: __('refused') }
                          }"
                          rules="required"
                          inline
                          />
                        </ve-form-group>
                       </ve-col>
                    </ve-row>
                    <ve-row v-if="role=='redcross-editor'">
                      <ve-col md6 xs12>
                        <ve-form-group>
                          <ve-label
                          :label="__('date')"
                          required
                          />
                          <datepicker v-model="ud.today"
                            name="today"
                            id="today"
                            :language="lang==='sv_SE' ? sv : en"
                            format="dd.MM.yyyy"
                            :monday-first="true"
                            :input-class="'calendar-icon form-control' + (ud.today ? ' is-valid' : ' is-invalid')"
                            :disabled="true"
                          ></datepicker>
                          <div class="invalid-feedback" v-if="showInvalidFeedback['today']"
                              :style="{ display: ud.today ? 'none' : 'block' }">
                            {{ __('required') }}
                          </div>
                        </ve-form-group>
                      </ve-col>
                    </ve-row>
                    <ve-row v-if="role=='redcross-editor'">
                      <ve-col>
                        <ve-headline :headline="__('servicePersonal')" size="1em"></ve-headline>
                      </ve-col>
                    </ve-row>
                    <ve-row v-if="role=='redcross-editor'">
                      <ve-col>
                        <ve-row>
                          <ve-input-field
                            md6 xs12
                            required
                            :label="__('firstName')"
                            type="text"
                            rules="required"
                            v-model="ud.servicePersonal.firstName"
                          />
                          <ve-input-field
                            md6 xs12
                            required
                            :label="__('lastName')"
                            type="text"
                            rules="required"
                            v-model="ud.servicePersonal.lastName"
                          />
                        </ve-row>
                      </ve-col>
                    </ve-row>
                    <ve-row v-if="role=='redcross-editor'">
                      <ve-col>
                        <ve-headline :headline="__('proceedConfirm')" size="1em"></ve-headline>
                      </ve-col>
                    </ve-row>
                    <br>
                <ve-row>
                  <ve-col>
                    <button class="btn btn-primary" type="button" @click="submit()">{{__('continue')}}</button>
                  </ve-col>
                </ve-row>
              </ve-form>
              <ve-row v-if="submitted">
                <ve-col>
                  <h5 v-html="__('submitMsg')" v-if="role!=='redcross-editor'"></h5>
                  <h5 v-html="__('forwardMsg')" v-if="role=='redcross-editor'"></h5>
                </ve-col>
              </ve-row>
            </div>
          </div>
          <!-- #endregion -->
        </ve-col>
      </ve-row>
    </div>
   
  </div>
</template>

<script>
export { default } from "./script.js";
</script>
<style>

.logo{
  margin-right: 10px;
  padding-bottom:5px;
  max-width: 120px;
}
.inline-input{
  display: inline;
}

.inline-input > input{
  display: inline;
  width: 100px;
}
#cover{
  width: 100%;
  overflow: auto;
  height: 100%;
  /* position: absolute; */
  bottom: 0;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  min-height:100vh;
  background-attachment: fixed;
}
/*Edge*/
@supports ( -ms-accelerator:true ) 
{
   
    #cover{
        overflow: auto;
        height: 100%;
    }
}
/*Ie 10/11*/
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) 
{
    #cover{
        overflow: hidden;
        height: 100%;    
    }
   
}
.info-text{
  font-size: 0.75em;
}
form > .table{
  border-top: 1px solid gray;
}
form > .table ~ .table {
    border-top: none;
}
.table{
  border-bottom: 1px solid gray;
}
.table > :first-child{
  border-left: 1px solid gray;
}
.table .col {
  border-right: 1px solid gray;
}
.table b{
  font-weight: bold;
}
.sub{
  font-size: 0.8em;
}

</style>
