@import "~@verified/veform/themes/default/style.scss";

$success: #008f70 !default;
$link-color: #008f70 !default;
$primary: #008f70 !default;

@font-face {
	font-family: "Caslon224Std-Book";
	src: url("/fonts/Caslon224Std-Book.otf") format("opentype");
}

body{
	font-family: 'Caslon224Std-Book', 'Times New Roman', Times, serif;
}

h1,h2,h3,h4,h5,h6{
	font-weight: bold;
}
.main {
	margin-top: 40px;
	max-width: 800px !important;
}
.v-select-title{
	font-size: 1rem;
}
input{
	min-height: 44px;
}
.btn-plus {
	background: #218BCB;
	display: inline-block;
	border-radius: 50%;
	color: #fff;
	padding: 6px;
	font-size: 26px;
	line-height: 15px;
	&:hover {
		cursor: pointer;
		background: #1c74aa;
	}
	margin: -5px 5px;
}

.btn-minus {
	background: #218BCB;
	display: inline-block;
	border-radius: 50%;
	color: #fff;
	padding: 6px 9px;
	font-size: 26px;
	line-height: 15px;
	&:hover {
		cursor: pointer;
		background: #1c74aa;
	}
	margin: -5px 5px;
}

.btn-minus-gray {
	background: #c1c1c1 !important;
	&:hover {
		cursor: not-allowed !important;
	}
}
.card{
	background: rgba(255, 255, 255, 0.85)
}
.form-control::placeholder{
	opacity: 0.5;
}
.loader {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	pointer-events: none;
	z-index: 1;
	padding: 10px;
	background: white;
	border-radius: 42px;	
  }
  .smooth,
  .smooth * {
	transition: all 0.5s ease;
  
	&.delay,
	& .delay {
	  transition-delay: 0.5s;
	}
  }
  .logo {
	  max-width: 200px !important;
  }
//   .tooltip-right {
// 	position: absolute;
// 	right: 0px;
// 	top: 25px;
// }